import React, { useState, useEffect, useRef } from "react";
import {
  View,
  Text,
  ImageBackground,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
  Image,
  TextInput,
  SafeAreaView,
  Button,
} from "react-native";
import { useNavigation, useRoute } from "@react-navigation/native";
import i18n from "../components/i18n";
import AsyncStorage from "../components/AsyncStorage";

function Header(props) {
  const navigation = useNavigation();
  const route = useRoute();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;

    AsyncStorage.getData("mCode").then((value) => {
      if (value) {
        setIsLoggedIn(true);
      }
    });

    return () => {
      mounted.current = false;
    };
  }, []);

  const switchLang = (lang) => {
    i18n.locale = lang;
    AsyncStorage.storeData("lang", lang);
    //location.reload();
    navigation.push(route.name);
  };

  return (
    <View className="hidden justify-start align-middle w-full md:flex z-50">
      <View className="bg-brandgold w-full h-12 min-h-[48px] flex-1 flex-row justify-center align-middle items-center">
        <TouchableOpacity
          onPress={() => {
            navigation.push("Login");
          }}
        >
          <Text className="text-sm uppercase text-[#111111] font-Crimson">
            {i18n.t("header.home")}
          </Text>
        </TouchableOpacity>
        {isLoggedIn && (
          <>
            <View className=" w-[1px] h-3 bg-[#111111] mx-4"></View>
            <TouchableOpacity
              onPress={() => {
                navigation.push("Profile");
              }}
            >
              <Text className="text-sm uppercase text-[#111111] font-Crimson">
                {i18n.t("header.profile")}
              </Text>
            </TouchableOpacity>
            {/* <View className=" w-[1px] h-3 bg-[#111111] mx-4"></View>
            <TouchableOpacity
              onPress={() => {
                navigation.push("FriendReferral");
              }}
            >
              <Text className="text-sm uppercase text-[#111111] font-Crimson">
                {i18n.t("header.friendReferral")}
              </Text>
            </TouchableOpacity> */}
          </>
        )}
        <View className=" w-[1px] h-3 bg-[#111111] mx-4"></View>
        <TouchableOpacity
          onPress={() => {
            window.open("https://ukstore.imperialtreasure.com/ ");
          }}
        >
          <Text className="text-sm uppercase text-[#111111] font-Crimson">
            {i18n.t("header.eStore")}
          </Text>
        </TouchableOpacity>
        <View className=" w-[1px] h-3 bg-[#111111] mx-4"></View>
        <TouchableOpacity
          onPress={() => {
            navigation.push("Privilege");
          }}
        >
          <Text className="text-sm uppercase text-[#111111] font-Crimson">
            {i18n.t("header.PRivileges")}
          </Text>
        </TouchableOpacity>

        {!isLoggedIn && (
          <>
            <View className=" w-[1px] h-3 bg-[#111111] mx-4"></View>
            <TouchableOpacity
              onPress={() => {
                navigation.push("SignUp");
              }}
            >
              <Text className="text-sm uppercase text-[#111111] font-Crimson">
                {i18n.t("header.register")}
              </Text>
            </TouchableOpacity>
          </>
        )}

        <View className=" w-[1px] h-3 bg-[#111111] mx-4"></View>
        <TouchableOpacity
          onPress={() => {
            window.open("https://www.imperialtreasure.com/uk/happenings");
          }}
        >
          <Text className="text-sm uppercase text-[#111111] font-Crimson">
            {i18n.t("header.highlights")}
          </Text>
        </TouchableOpacity>
        <View className=" w-[1px] h-3 bg-[#111111] mx-4"></View>
        <TouchableOpacity
          onPress={() => {
            navigation.push("FAQ");
          }}
        >
          <Text className="text-sm uppercase text-[#111111] font-Crimson">
            {i18n.t("header.faq")}
          </Text>
        </TouchableOpacity>
        <View className=" w-[1px] h-3 bg-[#111111] mx-4"></View>
        <TouchableOpacity
          onPress={() => {
            navigation.push("Tnc");
          }}
        >
          <Text className="text-sm uppercase text-[#111111] font-Crimson">
            {i18n.t("header.tnp")}
          </Text>
        </TouchableOpacity>

        {i18n.locale == "zh" && (
          <>
            <View className=" w-[1px] h-3 bg-[#111111] mx-4"></View>
            <TouchableOpacity
              onPress={() => {
                switchLang("en");
              }}
            >
              <Text className="text-sm uppercase text-[#111111] font-Crimson">
                ENG
              </Text>
            </TouchableOpacity>
          </>
        )}

        {i18n.locale != "zh" && (
          <>
            <View className=" w-[1px] h-3 bg-[#111111] mx-4"></View>
            <TouchableOpacity
              onPress={() => {
                switchLang("zh");
              }}
            >
              <Text className="text-sm uppercase text-[#111111] font-Crimson">
                中文
              </Text>
            </TouchableOpacity>
          </>
        )}
      </View>
      <ImageBackground
        source={require("../assets/logo.png")}
        resizeMode="contain"
        className="w-20 h-32 my-4 absolute top-4 left-4"
      ></ImageBackground>
    </View>
  );
}

export default Header;

import React, { useState, useEffect, useRef } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  SafeAreaView,
  ActivityIndicator,
  Button,
  Image,
  TextInput,
  ScrollView,
  Modal,
  StyleSheet,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import MobileHeader from "../components/mHeader";
import Footer from "../components/footer";
import InsideHead from "../components/insideHeader";
import AsyncStorage from "../components/AsyncStorage";
import axios from "axios";
import { API_MIDDLEWARE, API_REGION } from "@env";
import i18n from "../components/i18n";
import TransactionDetails from "./transactionDetails";
import CookieConsent from "../components/CookieConsent";
import NewMobileHeader from "../components/newMobileHeader";

const Transaction = () => {
  const navigation = useNavigation();

  const [memberCode, setMemberCode] = useState("");
  const [memberRegion, setMemberRegion] = useState("");
  const [member, setMember] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;

    AsyncStorage.getData("mCode").then((value) => {
      if (!value) {
        navigation.push("Login");
      } else {
        let loginDetail = JSON.parse(value);
        setMemberCode(loginDetail.code);
        setMemberRegion(loginDetail.region);

        let data = {
          code: loginDetail.code,
          username: loginDetail.username,
          region: loginDetail.region,
        };

        customerGet(data, (customer) => {
          console.log("Customer :", customer);

          customer.spend_required = parseInt(customer.spend_required);
          customer.spend_this_tier = parseInt(customer.spend_this_tier);

          setMember(customer);

          setIsLoading(false);
        });
      }
    });

    return () => {
      mounted.current = false;
    };
  }, []);

  const customerGet = (data, callback) => {
    let formData = new FormData();
    formData.append("method", "getCust");
    formData.append("code", data.code);
    formData.append("username", data.username);
    formData.append("region", data.region);

    axios
      .post(API_MIDDLEWARE, formData)
      .then(function (response) {
        callback(response.data);
      })
      .catch(function (error) {
        callback(false);
      });
  };

  return (
    <SafeAreaView
      className="min-h-full bg-[#F5F5F5]"
      pointerEvents={!isLoading ? "auto" : "none"}
    >
      {isLoading && (
        <View
          className="left-0 right-0 top-0 bottom-0 justify-center items-center bg-[#f3f3f394] z-10 w-full h-full"
          style={{ position: "fixed" }}
          pointerEvents={"none"}
        >
          <ActivityIndicator size="large" color={"#BD9B60"} />
        </View>
      )}

      <InsideHead />
      <NewMobileHeader />
      <MobileHeader
        title={i18n.t("profile.title")}
        backTo={"Profile"}
        logout={"Login"}
      />

      <View className="flex-1 h-auto">
        <View className="w-full md:w-4/5 max-w-7xl m-auto mt-3 pr-4 hidden md:flex">
          <TouchableOpacity
            onPress={() => {
              navigation.push("Login");
            }}
          >
            <Text className="text-brandgold w-auto h-8 leading-8 font-normal text-right font-PingFangSC">
              {i18n.t("profile.logout")}
            </Text>
          </TouchableOpacity>
        </View>

        {/* Transaction Record Area */}
        <TransactionDetails />
      </View>

      <Footer />
      <CookieConsent />
    </SafeAreaView>
  );
};

export default Transaction;

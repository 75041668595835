import React, { useState, useEffect, UseRef } from "react";
import {
  View,
  ScrollView,
  Text,
  Image,
  TextInput,
  TouchableOpacity,
  SafeAreaView,
  ActivityIndicator,
  Modal,
  Input,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import InsideHead from "../components/insideHeader";
import MobileHeader from "../components/mHeader";
import Footer from "../components/footer";
import axios from "axios";
import { API_MIDDLEWARE, API_REGION } from "@env";
import i18n from "../components/i18n";
import AsyncStorage from "../components/AsyncStorage";
import IntlPhoneInput from "../components/IntlPhoneInput";
import CookieConsent from "../components/CookieConsent";
import NewMobileHeader from "../components/newMobileHeader";

const FriendReferral = () => {
  const navigation = useNavigation();
  const [isLoading, setIsLoading] = useState(false);
  // const [refereeEmail, setRefereeEmail] = useState("");
  // const [refereeEmailErr, setRefereeEmailErr] = useState(false);
  const [refereeMobile, setRefereeMobile] = useState("");
  const [refereeMobileErr, setRefereeMobileErr] = useState(false);
  const [phoneUniqueErr, setPhoneUniqueErr] = useState(false);
  const [validPhone, setValidPhone] = useState(false);
  const [dialCode, setDialCode] = useState("");
  const [refereeName, setRefereeName] = useState("");
  const [refereeNameErr, setRefereeNameErr] = useState(false);
  const [refSuccessMsg, setRefSuccessMsg] = useState(false);
  const [refErrorMsg, setRefErrorMsg] = useState(false); // to check duplicated member
  const [memberCode, setMemberCode] = useState("");
  const [memberRegion, setMemberRegion] = useState("");
  const [successOverlay, setSuccessOverlay] = useState(false);

  useEffect(() => {
    AsyncStorage.getData("mCode").then((value) => {
      if (!value) {
        navigation.push("Login");
      } else {
        let loginDetail = JSON.parse(value);
        setMemberCode(loginDetail.code);
        setMemberRegion(loginDetail.region);
      }
    });
  }, []);

  // const validEmail = (email) => {
  //   var re =
  //     /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  //   return re.test(email);
  // };

  const submitForm = () => {
    let errors = [];
    setIsLoading(true);
    // setRefereeEmailErr(false);
    setRefereeMobileErr(false);
    setPhoneUniqueErr(false);
    setRefereeNameErr(false);
    setSuccessOverlay(false);

    // if (refereeEmail == "") {
    //   errors.push("err");
    //   setRefereeEmailErr(true);
    // }

    // if (!validEmail(refereeEmail)) {
    //   errors.push("err");
    //   setRefereeEmailErr(true);
    // }

    if (refereeMobile == "" || !validPhone) {
      errors.push("err");
      setRefereeMobileErr(true);
    }

    if (refereeName == "") {
      errors.push("err");
      setRefereeNameErr(true);
    }

    if (!errors.length) {
      let data = {
        // email: refereeEmail,
        mobile: dialCode + refereeMobile.replace(/\s/g, "").replace(/^0+/, ''),
        name: refereeName,
        code: memberCode,
      };

      // console.log("data :", data);

      checkUnique(data, (unique) => {
        console.log("unique :", unique);
        setIsLoading(true);
        setRefSuccessMsg(false);
        setRefErrorMsg(false);

        if (unique.status == "success") {
          setIsLoading(false);
          console.log("Mobile number is valid for referral");

          addReferralFrd(data, (c1) => {
            setIsLoading(true);
            setRefSuccessMsg(false);
            setRefErrorMsg(false);
            console.log("data :", data);
            console.log("c1 :", c1);
            console.log("referral code :", c1.referral_code);

            if (c1.status == "success") {
              setSuccessOverlay(true);
              console.log("Friend referral successful");
              setRefSuccessMsg(true);
              // setRefereeEmail("");
              setRefereeMobile("");
              setRefereeName("");
              setIsLoading(false);
            } else if (
              c1.status == "error" &&
              c1.hint.mobile.message == "invalid mobile number"
            ) {
              console.log("Invalid mobile number");
              setRefereeMobileErr(true);
              setRefSuccessMsg(false);
              setIsLoading(false);
            } else {
              console.log("Friend referral fail");
              setRefSuccessMsg(false);
              setIsLoading(false);
            }
          });
        } else if (unique.status == "failed") {
          // if (unique.message == "Email already used") {
          //   console.log("Email has already been registered");
          //   setRefSuccessMsg(false);
          //   setRefErrorMsg(true);
          // }
          if (unique.message == "Mobile already used") {
            console.log("Mobile number has already been registered");
            setRefSuccessMsg(false);
            setRefErrorMsg(true);
            setIsLoading(false);
          }
        } else {
          setIsLoading(false);
        }
        setIsLoading(false);
      });
    } else {
      setIsLoading(false);
    }
  };

  const onChangeText = ({
    dialCode,
    unmaskedPhoneNumber,
    phoneNumber,
    isVerified,
  }) => {
    console.log(dialCode, unmaskedPhoneNumber, phoneNumber, isVerified);
    setRefereeMobile(phoneNumber.replaceAll("-", ""));
    setDialCode(dialCode.replace("+", ""));
    setValidPhone(isVerified);
  };

  const addReferralFrd = async (data, callback) => {
    let formData = new FormData();
    formData.append("method", "sendReferral");
    formData.append("code", data.code);
    formData.append("name", data.name);
    // formData.append("email", data.email);
    formData.append("mobile", data.mobile);
    formData.append("region", API_REGION);

    try {
      const response = await axios.post(API_MIDDLEWARE, formData);
      // return response.data;
      callback(response.data);
    } catch (error) {
      console.log("addReferralFrd error :", error);
      throw error;
    }
  };

  const checkUnique = async (data, callback) => {
    let formData = new FormData();
    formData.set("method", "checkDup");
    formData.set("email", data.email);
    formData.set("mobile", data.mobile);
    formData.set("region", API_REGION);

    try {
      const response = await axios.post(API_MIDDLEWARE, formData);
      callback(response.data);
    } catch (error) {
      console.log("checkUnique error :", error);
      throw error;
    }
  };

  return (
    <SafeAreaView
      className="flex-1 justify-start items-center w-full bg-white md:bg-[#F5F5F5]"
      pointerEvents={!isLoading ? "auto" : "none"}
    >
      {isLoading && (
        <View
          className="absolute left-0 right-0 top-0 bottom-0 justify-center items-center bg-[#f3f3f394] z-10 w-full h-screen"
          pointerEvents={"none"}
        >
          <ActivityIndicator size="large" color={"#BD9B60"} />
        </View>
      )}
      <InsideHead />
      <NewMobileHeader />
      <MobileHeader title={i18n.t("profile.title")} backTo={"Profile"} />

      <ScrollView className="w-full flex-1 h-auto">
        <View className="md:flex md:w-4/5 max-w-7xl m-auto mt-6 mb-4 flex-row items-center hidden md:flex">
          <Image
            className="w-4 h-4 mr-1"
            source={require("../assets/icons/icon-home.png")}
          />
          <TouchableOpacity onPress={() => navigation.push("Profile")}>
            <Text className="text-sm font-normal text-[#4E5969] font-PingFangSC">
              {i18n.t("editProfile.member_profile")}
            </Text>
          </TouchableOpacity>
          <Text> / </Text>
          <Text className="font-PingFangSC">
            {i18n.t("friendReferral.title")}
          </Text>
        </View>

        <View className="w-11/12 md:w-4/5 max-w-7xl h-auto m-auto bg-white p-5 md:mt-6 md:flex">
          <View className="flex">
            <Text className=" text-base text-2xl md:text-3xl text-brandgold md:text-black font-semibold font-Crimson">
              {i18n.t("friendReferral.title")}
            </Text>
          </View>

          <View className="w-full h-[0.5px] bg-brandgold mt-3"></View>

          <View className="md:flex md:flex-row md:flex-wrap justify-between pb-4 mt-4">
            {/* <View className="md:w-[48%] md:mt-4 mb-4">
              <Text className="text-[#4E5969] text-sm mt-1 mb-1 font-PingFangSC">
                {i18n.t("friendReferral.email")} :
              </Text>
              <TextInput
                placeholder={i18n.t("friendReferral.referee_email")}
                className="h-9 w-full bg-[#F2F3F5] color-[#121212] px-4"
                value={refereeEmail}
                onChangeText={setRefereeEmail}
              ></TextInput>
              {refereeEmailErr && (
                <Text className="color-red-500 my-1">
                  {i18n.t("friendReferral.emailAddress_errMsg")}
                </Text>
              )}
            </View> */}

            <View className="md:w-[48%] md:mt-4 mb-4">
              <Text className="text-[#4E5969] text-sm mt-1 mb-1 font-PingFangSC">
                {i18n.t("friendReferral.mobile")} :
              </Text>

              <IntlPhoneInput
                onChangeText={onChangeText}
                defaultCountry={"GB"}
                containerStyle={{
                  flex: 1,
                  flexDirection: "row",
                  paddingHorizontal: 0,
                }}
                flagStyle={{ display: "none" }}
                phoneInputStyle={{
                  color: "#86909C",
                  height: 34,
                  width: "100%",
                  borderWidth: 1,
                  borderColor: "#F2F3F5",
                  backgroundColor: "#F2F3F5",
                  paddingHorizontal: 15,
                }}
                dialCodeTextStyle={{
                  color: "#86909C",
                  lineHeight: 34,
                  borderColor: "#F2F3F5",
                  backgroundColor: "#F2F3F5",
                  paddingHorizontal: 15,
                }}
                placeholder={i18n.t("friendReferral.referee_mobile")}
              />

              {refereeMobileErr && (
                <Text className="color-red-500 my-1">
                  {i18n.t("friendReferral.mobile_errMsg")}
                </Text>
              )}

              {phoneUniqueErr && (
                <Text className="color-red-500 my-1">
                  {i18n.t("friendReferral.mobile_errMsg2")}
                </Text>
              )}
            </View>

            <View className="md:w-[48%] md:mt-4 mb-4">
              <Text className="text-[#4E5969] text-sm mt-1 mb-1 font-PingFangSC">
                {i18n.t("friendReferral.name")} :
              </Text>
              <TextInput
                placeholder={i18n.t("friendReferral.referee_name")}
                className="h-9 w-full bg-[#F2F3F5] color-[#121212] px-4"
                value={refereeName}
                onChangeText={setRefereeName}
              ></TextInput>
              {refereeNameErr && (
                <Text className="color-red-500 my-1">
                  {i18n.t("friendReferral.name_errMsg")}
                </Text>
              )}
            </View>
          </View>

          {refSuccessMsg && (
            <Text className="text-green-500 mt-1 mb-3">
              {i18n.t("friendReferral.referral_sucMsg")}
            </Text>
          )}

          {refErrorMsg && (
            <Text className="color-red-500 mt-1 mb-3">
              {i18n.t("friendReferral.referral_errMsg")}
            </Text>
          )}

          <View className="md:flex md:flex-row md:flex-wrap justify-between pb-4 mt-4 my-auto">
            <TouchableOpacity
              className="w-auto  h-8 max-h-8 bg-brandgold md:mt-8 mt-4 px-4 "
              onPress={() => submitForm()}
            >
              <Text className="text-white w-full 10h- leading-8 text-center font-PingFangSC">
                {i18n.t("friendReferral.submit")}
              </Text>
            </TouchableOpacity>
          </View>
          <Modal
            animationType="slide"
            visible={successOverlay}
            transparent={true}
          >
            <View
              className="h-screen top-0 right-0 bottom-0 left-0 justify-center items-center bg-[#00000075]"
              // style={{ position: "fixed" }}
            >
              <View className="w-11/12 max-w-xs bg-white px-6 py-10 relative">
                <TouchableOpacity
                  className="absolute right-1 top-1"
                  onPress={() => {
                    setSuccessOverlay(false);
                    location.reload();
                  }}
                >
                  <Image
                    className="w-5 h-5"
                    source={require("../assets/btn-close.png")}
                  />
                </TouchableOpacity>

                <Text className="text-xl font-semibold text-[#111111] text-center py-8 uppercase font-Crimson">
                  {i18n.t("friendReferral.referral_sucMsg")}
                </Text>

                <View className="text-right flex items-end mb-8">
                  <TouchableOpacity
                    className="w-full h-8 max-h-8 bg-brandgold mt-4 mr-0"
                    onPress={() => {
                      setSuccessOverlay(false);
                      location.reload();
                    }}
                  >
                    <Text className="text-white w-full h-8 leading-8 text-center font-normal font-PingFangSC">
                      {i18n.t("editProfile.done")}
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </Modal>
        </View>
      </ScrollView>
      <Footer />
      <CookieConsent />
    </SafeAreaView>
  );
};

export default FriendReferral;

import React, { useState, useRef, useEffect } from "react";
import {
  StyleSheet,
  View,
  ScrollView,
  Text,
  Image,
  TouchableOpacity,
  SafeAreaView,
  ActivityIndicator,
  Modal,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import MobileHeader from "../components/mHeader";
import Footer from "../components/footer";
import InsideHeader from "../components/insideHeader";
import AsyncStorage from "../components/AsyncStorage";
import axios from "axios";
import { API_MIDDLEWARE, API_REGION } from "@env";
import moment from "moment";
import CookieConsent from "../components/CookieConsent";
import i18n from "../components/i18n";
import NewMobileHeader from "../components/newMobileHeader";

const Coupon = () => {
  const navigation = useNavigation();

  const [isLoading, setIsLoading] = useState(true);
  const [coupons, setCoupons] = useState([]);
  const [couponStatus, setCouponStatus] = useState("active");
  const [storeList, setStoreList] = useState({});
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);

  const mounted = useRef(false);

  const scrollViewRef = useRef();

  useEffect(() => {
    mounted.current = true;

    AsyncStorage.getData("mCode").then((value) => {
      if (!value) {
        navigation.push("Login");
      } else {
        let loginDetail = JSON.parse(value);

        let couponData = {
          code: loginDetail.code,
          offset: offset,
          limit: limit,
          region: loginDetail.region,
          order: "expiry_date",
          order_dir: "asc",
          status: "issued",
        };

        let tempStoreList = {};

        getStoreList((returnList) => {
          // console.log("returnList :", returnList);
          for (let i = 0; i < returnList.store.length; i++) {
            tempStoreList[returnList.store[i].code] = returnList.store[i];
          }

          setStoreList(tempStoreList);

          couponGet(couponData, (coupons) => {
            setTotalCount(coupons.general.ISSUED);
            setTotalPages(Math.ceil(coupons.general.ISSUED / limit));

            setIsLoading(false);
            if (coupons.status == "success") {
              let couponData = coupons.coupons;
              console.log("couponData :", couponData);

              let couponList = [];

              for (let i = 0; i < couponData.length; i++) {
                let storeName = "";

                if (
                  couponData[i].redeem_store == null ||
                  couponData[i].redeem_store == ""
                ) {
                  storeName = "N/A";
                } else {
                  if (i18n.locale == "zh") {
                    storeList[couponData[i].redeem_store.store_code]
                      ? (storeName =
                          storeList[couponData[i].redeem_store.store_code]
                            .ch_name)
                      : (storeName =
                          storeList[couponData[i].redeem_store.store_code]
                            .custname);
                  }
                }

                couponData[i].storename = storeName;

                couponList.push(couponData[i]);
              }

              setCoupons(couponList);
              setIsLoading(false);
              handleScrollToTop();
            }
          });
        });
      }
    });
  }, []);

  const handleActiveStatus = () => {
    setCouponStatus("active");
    renderCoupons(1, "active");
    return;
  };

  const handleRedeemedStatus = () => {
    setCouponStatus("redeemed");
    renderCoupons(1, "redeemed");
    return;
  };

  const handleExpiredStatus = () => {
    setCouponStatus("expired");
    renderCoupons(1, "expired");
    return;
  };

  const handleNext = () => {
    renderCoupons(currentPage + 1);
  };

  const handlePrevious = () => {
    renderCoupons(currentPage - 1);
  };

  // Function to handle page scroll to top:
  const handleScrollToTop = () => {
    scrollViewRef.current.scrollTo({ x: 0, y: 0, animated: true });
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];

    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <TouchableOpacity
          key={i}
          style={[
            styles.pageNumber,
            offset / limit + 1 === i ? styles.activePageNumber : null,
          ]}
          onPress={() => renderCoupons(i, null)}
        >
          <Text style={styles.pageNumberText}>{i}</Text>
        </TouchableOpacity>
      );
    }
    return pageNumbers;
  };

  const renderCoupons = (page, selectedStatus) => {
    setIsLoading(true);

    AsyncStorage.getData("mCode").then((value) => {
      if (!value) {
        navigation.push("Login");
      } else {
        let loginDetail = JSON.parse(value);

        setCurrentPage(page);

        let newOffset = (page - 1) * limit;

        setOffset(newOffset);

        let couponData = {
          code: loginDetail.code,
          offset: newOffset,
          limit: limit,
          region: loginDetail.region,
          order: "expiry_date",
          order_dir: "asc",
        };

        console.log("*selectedStatus :", selectedStatus);

        if (selectedStatus == null) {
          selectedStatus = couponStatus;
        }
        if (selectedStatus == "active") {
          couponData.status = "issued";
        } else if (selectedStatus == "redeemed") {
          couponData.status = "redeemed";
        } else if (selectedStatus == "expired") {
          couponData.status = "expired";
        }

        couponGet(couponData, (coupons) => {
          console.log("*coupons details* :", coupons);

          if (couponData.status == "issued") {
            setTotalCount(coupons.general.ISSUED);
            setTotalPages(Math.ceil(coupons.general.ISSUED / limit));
          } else if (couponData.status == "redeemed") {
            setTotalCount(coupons.general.USED);
            setTotalPages(Math.ceil(coupons.general.USED / limit));
          } else if (couponData.status == "expired") {
            setTotalCount(coupons.general.EXPIRED);
            setTotalPages(Math.ceil(coupons.general.EXPIRED / limit));
          }

          setIsLoading(false);
          if (coupons.status == "success") {
            let couponData = coupons.coupons;
            console.log("*couponData* :", couponData);

            let couponList = [];

            for (let i = 0; i < couponData.length; i++) {
              let storeName = "";

              if (
                couponData[i].redeem_store == null ||
                couponData[i].redeem_store == ""
              ) {
                // console.log("No redeemed storeName");
                storeName = "N/A";
              } else {
                if (i18n.locale == "zh") {
                  storeList[couponData[i].redeem_store.store_code]
                    ? (storeName =
                        storeList[couponData[i].redeem_store.store_code]
                          .ch_name)
                    : (storeName =
                        storeList[couponData[i].redeem_store.store_code]
                          .custname);
                }
              }

              couponData[i].storename = storeName;

              couponList.push(couponData[i]);
            }
            setCoupons(couponList);
            setIsLoading(false);
            handleScrollToTop();
          }
        });
      }
    });
  };

  const couponGet = (data, callback) => {
    let formData = new FormData();
    formData.append("method", "getCoupon");
    formData.set("code", data.code);
    formData.set("offset", data.offset);
    formData.set("limit", data.limit);
    formData.set("region", data.region);
    formData.set("order", data.order);
    formData.set("order_dir", data.order_dir);
    formData.set("status", data.status);

    axios
      .post(API_MIDDLEWARE, formData)
      .then(function (response) {
        callback(response.data);
      })
      .catch(function (error) {
        callback(false);
      });
  };

  const getCouponSummary = (data, callback) => {
    let formData = new FormData();
    formData.append("method", "getCouponSummary");
    formData.set("region", data.region);
    formData.set("code", data.code);

    axios
      .post(API_MIDDLEWARE.formData)
      .then(function (response) {
        callback(response.data);
      })
      .catch(function (error) {
        callback(false);
      });
  };

  const getStoreList = async (callback) => {
    let formData = new FormData();
    formData.set("method", "getStoreList");
    formData.set("region", API_REGION);

    try {
      const response = await axios.post(API_MIDDLEWARE, formData);
      callback(response.data);
    } catch (error) {
      console.log("getStoreList error :", error);
      throw error;
    }
  };

  const filterStatus = (cStatus) => {
    if (couponStatus == "all") {
      return true;
    }

    if (couponStatus == "active") {
      if (cStatus == "ISSUED") {
        return true;
      } else {
        return false;
      }
    }

    if (couponStatus == "redeemed") {
      if (cStatus == "USED") {
        return true;
      } else {
        return false;
      }
    }

    if (couponStatus == "expired") {
      if (cStatus == "EXPIRED") {
        return true;
      } else {
        return false;
      }
    }

    if (couponStatus == "notissued") {
      if (cStatus == "USED" || cStatus == "EXPIRED") {
        return true;
      } else {
        return false;
      }
    }
  };

  const couponCodeMask = (code) => {
    let targetStr = code.substring(code.length - 3, code.length);

    return code.replace(targetStr, "xxx");
  };

  const filterStyle = (cStatus) => {
    if (couponStatus == "all") {
      return true;
    }
  };

  const dateFormat = (date) => {
    if (date && date != "N/A") {
      return moment(date).format("DD/MM/YYYY");
    } else {
      return "N/A";
    }
  };

  return (
    <SafeAreaView className="bg-[#F5F5F5] min-h-full">
      {isLoading && (
        <View
          className="absolute left-0 right-0 top-0 bottom-0 justify-center items-center bg-[#f3f3f394] z-10 w-full h-full"
          pointerEvents={"none"}
        >
          <ActivityIndicator size="large" color={"#BD9B60"} />
        </View>
      )}
      <InsideHeader />
      <NewMobileHeader />
      <MobileHeader title={i18n.t("coupon.title")} backTo={"Profile"} />

      <ScrollView className="flex-1 h-auto" ref={scrollViewRef}>
        <View className="hidden md:flex md:w-4/5 max-w-7xl m-auto mt-6 mb-4 flex-row items-center">
          <Image
            className="w-4 h-4 mr-1"
            source={require("../assets/icons/icon-home.png")}
          />
          <TouchableOpacity onPress={() => navigation.push("Profile")}>
            <Text className="text-sm font-normal text-[#4E5969] font-PingFangSC">
              {i18n.t("coupon.member_profile")}
            </Text>
          </TouchableOpacity>
          <Text> / </Text>
          <Text className="font-PingFangSC">{i18n.t("coupon.coupon")}</Text>
        </View>

        <View className="flex w-full md:w-4/5 max-w-7xl m-auto bg-white p-5 flex-col">
          <View>
            <Text className="hidden md:flex text-2xl font-semibold text-black font-Crimson">
              {i18n.t("coupon.coupon")}
            </Text>
          </View>

          {/* <View>
            <Text className="text-sm font-semibold text-[#4E5979] font-Crimson mt-2">
              {i18n.t("coupon.birthdayNote")}
            </Text>
          </View> */}

          <View className="w-full flex flex-row flex-wrap items-center justify-between border-b border-b-[#E5E6E8] mt-5">
            <View className="flex flex-row flex-wrap items-center justify-start">
              <TouchableOpacity onPress={() => handleActiveStatus()}>
                <Text
                  className={
                    couponStatus == "active"
                      ? "text-sm font-normal text-[#BD9B60] py-2 mr-8 border-b border-b-[#BD9B60] font-PingFangSC"
                      : "text-sm font-normal text-[#4E5979] py-2 mr-8 font-PingFangSC"
                  }
                >
                  {i18n.t("coupon.active")}
                </Text>
              </TouchableOpacity>

              <TouchableOpacity onPress={() => handleRedeemedStatus()}>
                <Text
                  className={
                    couponStatus == "redeemed"
                      ? "text-sm font-normal text-[#BD9B60] py-2 mr-8 border-b border-b-[#BD9B60] font-PingFangSC"
                      : "text-sm font-normal text-[#4E5979] py-2 mr-8 font-PingFangSC"
                  }
                >
                  {i18n.t("coupon.redeemed")}
                </Text>
              </TouchableOpacity>

              <TouchableOpacity onPress={() => handleExpiredStatus()}>
                <Text
                  className={
                    couponStatus == "expired"
                      ? "text-sm font-normal text-[#BD9B60] py-2 mr-8 border-b border-b-[#BD9B60] font-PingFangSC"
                      : "text-sm font-normal text-[#4E5979] py-2 mr-8 font-PingFangSC"
                  }
                >
                  {i18n.t("coupon.expired")}
                </Text>
              </TouchableOpacity>
            </View>
          </View>

          {coupons.length <= 0 && (
            <Text className="text-sm font-normal text-[#84818A] font-Crimson flex items-center justify-center mt-20 mb-20">
              {i18n.t("coupon.no_coupon_msg")}
            </Text>
          )}

          {coupons.length > 0 &&
            coupons.map((item, index) => {
              return (
                filterStatus(item.status) && (
                  <View
                    className="flex flex-wrap w-[95%] m-auto md:flex-nowrap flex-col md:flex-row items-start justify-between py-5 border-b border-b-[#E5E6E8]"
                    key={index}
                  >
                    <View className="w-auto pl-5 pr-0 md:max-w-[65%] px-0 md:px-5">
                      <Text className="text-base font-bold md:text-xl text-black font-Crimson">
                        {item.name}
                      </Text>

                      <Text className="text-xs md:text-sm text-black font-PingFangSC">
                        {/* {couponCodeMask(item.code)} */}
                        {item.code}
                      </Text>
                      <Text className="text-xs font-normal md:text-sm text-[#86909C] font-PingFangSC">
                        {i18n.t("coupon.expiry_date")}
                        {dateFormat(item.expiry_date)}
                      </Text>

                      <>
                        {item.redeem_store && (
                          <Text className="text-xs font-normal md:text-sm text-[#86909C] font-PingFangSC">
                            {i18n.t("coupon.redeemed_store")} {item.storename}
                          </Text>
                        )}
                      </>

                      <Text className="text-xs font-normal mt-[5px] md:text-sm md:mt-5 text-black font-PingFangSC">
                        {item.conditions}
                      </Text>
                    </View>

                    {item.status == "ISSUED" && (
                      <View className="py-3 md:py-0">
                        <View className="w-auto h-8 max-h-8 bg-brandgold px-4 rounded-sm">
                          <Text className="text-white w-full h-8 leading-8 text-center font-normal font-PingFangSC">
                            {i18n.t("coupon.active")}
                          </Text>
                        </View>
                      </View>
                    )}

                    {item.status == "USED" && (
                      <View className="py-3 md:py-0">
                        <View className="w-auto h-8 max-h-8 bg-[#333333] px-4 rounded-sm">
                          <Text className="text-white w-full h-8 leading-8 text-center font-normal font-PingFangSC">
                            {i18n.t("coupon.redeemed")}
                          </Text>
                        </View>
                      </View>
                    )}

                    {item.status == "EXPIRED" && (
                      <View className="py-3 md:py-0">
                        <View className="w-auto h-8 max-h-8 bg-[#DDDDDD] px-4 rounded-sm">
                          <Text className="text-white w-full h-8 leading-8 text-center font-normal font-PingFangSC">
                            {i18n.t("coupon.expired")}
                          </Text>
                        </View>
                      </View>
                    )}
                  </View>
                )
              );
            })}

          <View style={styles.paginationContainer}>
            <TouchableOpacity
              style={styles.paginationButton}
              onPress={handlePrevious}
              disabled={currentPage === 1}
            >
              <Text style={styles.paginationButtonText}>Previous</Text>
            </TouchableOpacity>

            <View style={styles.pageNumbersContainer}>
              {renderPageNumbers()}
            </View>

            <TouchableOpacity
              style={styles.paginationButton}
              onPress={handleNext}
              disabled={currentPage === totalPages}
            >
              <Text style={styles.paginationButtonText}>Next</Text>
            </TouchableOpacity>
          </View>
        </View>
      </ScrollView>

      <Footer />
      <CookieConsent />
    </SafeAreaView>
  );
};

const pickerSelectStyles = StyleSheet.create({
  inputIOS: {
    color: "#86909C",
    height: 32,
    width: "100%",
    borderWidth: 1,
    borderColor: "#F2F3F5",
    backgroundColor: "#F2F3F5",
    paddingHorizontal: 15,
    marginBottom: 6,
    borderRadius: 0,
  },
  inputAndroid: {
    color: "#86909C",
    height: 38,
    fontSize: 15,
    lineHeight: 38,
    width: "100%",
    backgroundColor: "#F2F3F5",
    borderColor: "#F2F3F5",
    borderWidth: 1,
    paddingHorizontal: 15,
  },
  inputWeb: {
    color: "#86909C",
    height: 32,
    width: "100%",
    borderWidth: 1,
    borderColor: "#F2F3F5",
    backgroundColor: "#F2F3F5",
    paddingHorizontal: 15,
    marginBottom: 6,
    borderRadius: 0,
  },
});

const styles = StyleSheet.create({
  paginationContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 10,
  },
  paginationButton: {
    backgroundColor: "#BD9B60",
    padding: 10,
    borderRadius: 5,
    marginHorizontal: 5,
  },
  paginationButtonText: {
    fontWeight: "semibold",
    color: "#FFFFFF",
  },
  pageNumbersContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  pageNumber: {
    backgroundColor: "lightgray",
    padding: 5,
    borderRadius: 5,
    marginHorizontal: 5,
  },
  activePageNumber: {
    backgroundColor: "#BD9B60",
  },
  pageNumberText: {
    fontWeight: "semibold",
    color: "#FFFFFF",
  },
});

export default Coupon;

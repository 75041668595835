import React from "react";
import {
  View,
  Text,
  Image,
  ImageBackground,
  TouchableOpacity,
  SafeAreaView,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import MobileHeader from "../components/mHeader";
import Footer from "../components/footer";
import Header from "../components/header";
import i18n from "../components/i18n";
import CookieConsent from "../components/CookieConsent";
import NewMobileHeader from "../components/newMobileHeader";

const Privilege = () => {
  const navigation = useNavigation();

  return (
    <View className="md:bg-[#efefef]">
      <ImageBackground
        source={require("../assets/login-bg.png")}
        resizeMode="cover"
        className="flex-1 justify-start items-center w-full h-full"
      >
        <SafeAreaView className="bg-white flex justify-start items-center w-full md:bg-transparent">
          <Header />
          <NewMobileHeader />
          <MobileHeader title={i18n.t("privilege.title")} />
          <View className="w-11/12 md:w-4/5 md:max-w-screen-xl md:h-auto md:m-auto md:bg-white md:p-7 mt-6 md:mb-24 pb-10">
            <Text className="text-black text-2xl mt-6 mb-4 uppercase font-Crimson md:text-center">
              {i18n.t("privilege.privilege")}
            </Text>
            <View className="w-full h-[0.5px] bg-brandgold mt-3"></View>
            <Text className="text-center text-base text-[#494949] mt-4 mb-6">
              {i18n.t("privilege.privelege_txt")}
            </Text>

            <View className="flex flex-col md:flex-row flex-wrap items-start justify-evenly w-full my-10 mx-auto">
              <View className="w-full md:w-[32%] min-h-[450px] bg-white border border-[#6D6E72] border-b-[12px] mb-4">
                <Text className="w-full bg-[#6D6E72] h-9 text-center text-white text-base leading-9">
                  {i18n.t("privilege.silver")}
                </Text>

                <View className="py-6 px-4">
                  <View className="flex items-center">
                    <Text className="flex-1 pl-1 text-sm text-[#303030] mt-4">
                      {i18n.t("privilege.silver_1")}
                    </Text>
                  </View>
                </View>
              </View>

              <View className="w-full md:w-[32%] min-h-[450px] bg-white border border-brandgold border-b-[12px] mb-4">
                <Text className="w-full bg-brandgold h-9 text-center text-white text-base leading-9">
                  {i18n.t("privilege.gold")}
                </Text>
                <View className="py-6 px-4">
                  <Text className="text-sm text-[#494949] mt-4 mb-6">
                    {i18n.t("privilege.gold_1")}
                  </Text>
                  <View className="mb-6 flex-row">
                    <Image
                      source={require("../assets/icons/gold_cash.png")}
                      className="w-[32px] h-[32px] block my-auto self-center mr-4"
                    />
                    <Text className="flex-1 pl-1 text-sm text-[#303030]">
                      {i18n.t("privilege.gold_2")}
                    </Text>
                  </View>

                  <View className="mb-6 flex-row">
                    <Image
                      source={require("../assets/icons/gold_complimentary.png")}
                      className="w-[32px] h-[32px] block my-auto self-center mr-4"
                    />
                    <Text className="flex-1 pl-1 text-sm text-[#303030]">
                      {i18n.t("privilege.gold_3")}
                    </Text>
                  </View>
                </View>
              </View>

              <View className="w-full md:w-[32%] min-h-[450px] bg-white border border-[#E2E2E4] border-b-[12px] mb-4">
                <Text className="w-full bg-[#E2E2E4] h-9 text-center text-black text-base leading-9">
                  {i18n.t("privilege.diamond")}
                </Text>
                <View className="py-6 px-4">
                  <Text className="text-sm text-[#494949] mt-4 mb-6">
                    {i18n.t("privilege.diamond_1")}
                  </Text>
                  <View className="mb-6 flex-row">
                    <Image
                      source={require("../assets/icons/diamond_cash.png")}
                      className="w-[32px] h-[32px] block my-auto self-center mr-4"
                    />
                    <Text className="flex-1 pl-1 text-sm text-[#303030]">
                      {i18n.t("privilege.diamond_2")}
                    </Text>
                  </View>
                  <View className="mb-6 flex-row">
                    <Image
                      source={require("../assets/icons/diamond_complimentary.png")}
                      className="w-[32px] h-[32px] block my-auto self-center mr-4"
                    />
                    <Text className="flex-1 pl-1 text-sm text-[#303030]">
                      {i18n.t("privilege.diamond_3")}
                    </Text>
                  </View>
                </View>
              </View>
            </View>

            <View className="flex items-center justify-center mb-10">
              <Text>{i18n.t("privilege.vat_note")}</Text>
            </View>

            <TouchableOpacity
              className="w-full h-10 max-h-10 bg-brandgold mt-4 md:w-[300px] md:m-auto md:block md:text-center md:mb-10"
              onPress={() => navigation.push("SignUp")}
            >
              <Text className="text-white w-full h-10 leading-10 text-center font-normal">
                {i18n.t("privilege.sign_up_now")}
              </Text>
            </TouchableOpacity>
          </View>

          <Footer />
          <CookieConsent />
        </SafeAreaView>
      </ImageBackground>
    </View>
  );
};

export default Privilege;

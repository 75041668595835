import React, { useEffect, useState } from "react";
import { View, ScrollView, Text, ImageBackground, SafeAreaView, TouchableOpacity, Image } from "react-native";
import { useNavigation } from "@react-navigation/native";
import MobileHeader from "../components/mHeader";
import Footer from "../components/footer";
import Header from "../components/header";
import i18n from "../components/i18n";
import CookieConsent from "../components/CookieConsent";
import AsyncStorage from "../components/AsyncStorage";
import NewMobileHeader from "../components/newMobileHeader";

const FAQ = () => {
  const navigation = useNavigation();
  const [lang, setLang] = useState("en");

  useEffect(() => {
    AsyncStorage.getData("lang").then((lang) => setLang(lang));
  }, []);

  return (
    <View className="flex-1 md:bg-[#efefef] w-full">
      <ImageBackground source={require("../assets/login-bg.png")} resizeMode="cover" className="flex-1 justify-start items-center w-full h-full">
        <SafeAreaView className="flex-1 bg-white flex justify-start items-center w-full md:bg-transparent">
          <NewMobileHeader />
          <MobileHeader title={i18n.t("faqs.faqs")} />
          <ScrollView className="w-full flex-1">
            <Header />
            <View className="items-center content-start">
              <View className="w-11/12 md:w-4/5 md:max-w-screen-xl md:h-auto md:m-auto md:bg-white md:p-7 mt-6 md:mb-24 pb-10">
                <Text className="text-black text-2xl mt-6 mb-4 uppercase font-Crimson md:text-center">{i18n.t("faqs.faqs")}</Text>

                <View className="w-full h-[0.5px] bg-brandgold mt-3"></View>

                <Text className="text-black text-base mt-6 mb-1 uppercase font-Crimson underline">{i18n.t("faqs.general")}</Text>
                <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">{i18n.t("faqs.general_q1")}</Text>
                <Text className="text-sm text-[#494949] mt-1 mb-6 ">{i18n.t("faqs.general_q1_opt1")}</Text>

                <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">{i18n.t("faqs.general_q2")}</Text>
                <Text className="text-sm text-[#494949] mt-1 mb-6 ">{i18n.t("faqs.general_q2_opt1")}</Text>

                <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">{i18n.t("faqs.general_q3")}</Text>

                <View className="mb-2 flex-row">
                  <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
                  <Text className="flex-1 pl-2 text-sm text-[#303030] font-semibold">{i18n.t("faqs.general_q3_opt2")}</Text>
                </View>
                <View className="mb-6 ml-10 flex-row">
                  <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
                  <Text className="flex-1 pl-2 text-sm text-[#303030]">{i18n.t("faqs.general_q3_opt2_1")}</Text>
                </View>

                <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">{i18n.t("faqs.general_q4")}</Text>
                <Text className="text-sm text-[#494949] mt-1 mb-6 ">{i18n.t("faqs.general_q4_opt1")}</Text>

                <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">{i18n.t("faqs.general_q5")}</Text>
                <Text className="text-sm text-[#494949] mt-1 mb-6 ">{i18n.t("faqs.general_q5_opt1")}</Text>

                <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">{i18n.t("faqs.general_q6")}</Text>
                <Text className="text-sm text-[#494949] mt-1 mb-6 ">{i18n.t("faqs.general_q6_opt1")}</Text>

                <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">{i18n.t("faqs.general_q7")}</Text>
                <Text className="text-sm text-[#494949] mt-1 mb-6 ">{i18n.t("faqs.general_q7_opt1")}</Text>

                <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">{i18n.t("faqs.general_q8")}</Text>
                <Text className="text-sm text-[#494949] mt-1 mb-6 ">{i18n.t("faqs.general_q8_opt1")}</Text>

                <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">{i18n.t("faqs.general_q9")}</Text>
                <Text className="text-sm text-[#494949] mt-1 mb-6 ">{i18n.t("faqs.general_q9_opt1")}</Text>

                <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">{i18n.t("faqs.general_q10")}</Text>
                <Text className="text-sm text-[#494949] mt-1 mb-6 ">{i18n.t("faqs.general_q10_opt1")}</Text>

                <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">{i18n.t("faqs.general_q11")}</Text>
                <Text className="text-sm text-[#494949] mt-1 mb-6 ">{i18n.t("faqs.general_q11_opt1")}</Text>

                <Text className="hidden text-sm text-[#494949] mt-1 mb-1 font-semibold">{i18n.t("faqs.general_q12")}</Text>
                <Text className="hidden text-sm text-[#494949] mt-1 mb-6 ">{i18n.t("faqs.general_q12_opt1")}</Text>

                <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">{i18n.t("faqs.general_q13")}</Text>
                <Text className="text-sm text-[#494949] mt-1 mb-6 ">{i18n.t("faqs.general_q13_opt1")}</Text>

                <View className="w-full h-[0.5px] bg-brandgold mt-3"></View>

                <Text className="text-black text-base mt-6 mb-1 uppercase font-Crimson underline">{i18n.t("faqs.membershipTypes")}</Text>
                <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">{i18n.t("faqs.membershipTypes_q1")}</Text>
                <Text className="text-sm text-[#494949] mt-1 mb-6 ">{i18n.t("faqs.membershipTypes_q1_opt1")}</Text>

                <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">{i18n.t("faqs.membershipTypes_q2")}</Text>
                <Text className="text-sm text-[#494949] mt-1 mb-1 ">{i18n.t("faqs.membershipTypes_q2_opt1")}</Text>
                <Text className="text-sm text-[#494949] mt-1 mb-1 ">{i18n.t("faqs.membershipTypes_q2_opt2")}</Text>
                <Text className="text-sm text-[#494949] mt-1 mb-1 ">{i18n.t("faqs.membershipTypes_q2_opt3")}</Text>
                <Text className="text-sm text-[#494949] mt-1 mb-6 ">{i18n.t("faqs.membershipTypes_q2_opt4")}</Text>

                <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">{i18n.t("faqs.membershipTypes_q3")}</Text>
                <Text className="text-sm text-[#494949] mt-1 mb-1 ">{i18n.t("faqs.membershipTypes_q3_opt1")}</Text>
                <Text className="text-sm text-[#494949] mt-1 mb-1 ">{i18n.t("faqs.membershipTypes_q3_opt2")}</Text>
                <Text className="text-sm text-[#494949] mt-1 mb-6 ">{i18n.t("faqs.membershipTypes_q3_opt3")}</Text>

                <View className="w-full h-[0.5px] bg-brandgold mt-3"></View>

                <Text className="text-black text-base mt-6 mb-1 uppercase font-Crimson underline">{i18n.t("faqs.membership")}</Text>

                <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">{i18n.t("faqs.membership_q1")}</Text>

                {/* Membership privileges table */}
                <ScrollView horizontal={true}>
                  <View className="mt-2 mb-4 min-w-[600px] overflow-hidden overflow-x-scroll">
                    <View className="mt-1">
                      <View className="flex flex-row bg-brandgold">
                        <Text className="w-[49%] border border-black border-r-0 px-4 py-3 text-sm font-semibold">{lang === "zh" ? i18n.t("faqs.privilege_type") : i18n.t("faqs.complimentary_treats")}</Text>
                        <Text className="w-[17%] border border-black border-r-0 px-4 py-3 text-sm font-semibold">{i18n.t("faqs.sliver")}</Text>
                        <Text className="w-[17%] border border-black border-r-0 px-4 py-3 text-sm font-semibold">{i18n.t("faqs.gold")}</Text>
                        <Text className="w-[17%] border border-black px-4 py-3 text-sm font-semibold">{i18n.t("faqs.diamond")}</Text>
                      </View>
                      {lang === "zh" && (
                        <>
                          <View className="flex flex-row bg-white">
                            <Text className="w-[49%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.priviledge_1_1")}</Text>
                            <Text className="w-[17%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.priviledge_1_2")}</Text>
                            <Text className="w-[17%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.priviledge_1_3")}</Text>
                            <Text className="w-[17%] border border-black border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.priviledge_1_4")}</Text>
                          </View>
                          <View className="flex flex-row bg-white">
                            <Text className="w-[49%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.priviledge_2_1")}</Text>
                            <Text className="w-[17%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.priviledge_2_2")}</Text>
                            <Text className="w-[17%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.priviledge_2_3")}</Text>
                            <Text className="w-[17%] border border-black border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.priviledge_2_4")}</Text>
                          </View>
                          <View className="flex flex-row bg-brandgold">
                            <Text className="w-full border border-black border-t-0 px-4 py-3 text-sm font-semibold">{i18n.t("faqs.complimentary_treats")}</Text>
                          </View>
                        </>
                      )}

                      <View className="flex flex-row bg-white">
                        <Text className="w-[49%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.complimentary_treats_1_1")}</Text>
                        <Text className="w-[17%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.complimentary_treats_1_2")}</Text>
                        <Text className="w-[17%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.complimentary_treats_1_3")}</Text>
                        <Text className="w-[17%] border border-black border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.complimentary_treats_1_4")}</Text>
                      </View>
                      <View className="flex flex-row bg-white">
                        <Text className="w-[49%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.complimentary_treats_2_1")}</Text>
                        <Text className="w-[17%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.complimentary_treats_2_2")}</Text>
                        <Text className="w-[17%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.complimentary_treats_2_3")}</Text>
                        <Text className="w-[17%] border border-black border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.complimentary_treats_2_4")}</Text>
                      </View>
                      <View className="flex flex-row bg-white">
                        <Text className="w-[49%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.complimentary_treats_3_1")}</Text>
                        <Text className="w-[17%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.complimentary_treats_3_2")}</Text>
                        <Text className="w-[17%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.complimentary_treats_3_3")}</Text>
                        <Text className="w-[17%] border border-black border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.complimentary_treats_3_4")}</Text>
                      </View>
                      <View className="flex flex-row bg-white">
                        <Text className="w-[49%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.complimentary_treats_4_1")}</Text>
                        <Text className="w-[17%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.complimentary_treats_4_2")}</Text>
                        <Text className="w-[17%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.complimentary_treats_4_3")}</Text>
                        <Text className="w-[17%] border border-black border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.complimentary_treats_4_4")}</Text>
                      </View>
                      <View className="flex flex-row bg-white">
                        <Text className="w-[49%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.complimentary_treats_5_1")}</Text>
                        <Text className="w-[17%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.complimentary_treats_5_2")}</Text>
                        <Text className="w-[17%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.complimentary_treats_5_3")}</Text>
                        <Text className="w-[17%] border border-black border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.complimentary_treats_5_4")}</Text>
                      </View>
                      <View className="flex flex-row bg-white">
                        <Text className="w-[49%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.complimentary_treats_6_1")}</Text>
                        <Text className="w-[17%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.complimentary_treats_6_2")}</Text>
                        <Text className="w-[17%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.complimentary_treats_6_3")}</Text>
                        <Text className="w-[17%] border border-black border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.complimentary_treats_6_4")}</Text>
                      </View>
                      <View className="flex flex-row bg-white">
                        <Text className="w-[49%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.complimentary_treats_7_1")}</Text>
                        <Text className="w-[17%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.complimentary_treats_7_2")}</Text>
                        <Text className="w-[17%] border border-black border-r-0 border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.complimentary_treats_7_3")}</Text>
                        <Text className="w-[17%] border border-black border-t-0 px-4 py-3 text-sm">{i18n.t("faqs.complimentary_treats_7_4")}</Text>
                      </View>
                    </View>
                  </View>
                </ScrollView>

                <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">{i18n.t("faqs.membership_q3")}</Text>
                <Text className="text-sm text-[#494949] mt-1 mb-6 ">{i18n.t("faqs.membership_q3_opt1")}</Text>

                <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">{i18n.t("faqs.membership_q4")}</Text>
                <Text className="text-sm text-[#494949] mt-1 mb-6 ">{i18n.t("faqs.membership_q4_1_1")}</Text>

                <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">{i18n.t("faqs.membership_q5")}</Text>
                <Text className="text-sm text-[#494949] mt-1 mb-6 ">{i18n.t("faqs.membership_q5_opt1")}</Text>

                <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">{i18n.t("faqs.membership_q6")}</Text>
                <Text className="text-sm text-[#494949] mt-1 mb-6 ">{i18n.t("faqs.membership_q6_opt1")}</Text>

                <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">{i18n.t("faqs.membership_q7")}</Text>
                <Text className="text-sm text-[#494949] mt-1 mb-6">{i18n.t("faqs.membership_q7_opt1")}</Text>

                <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">{i18n.t("faqs.membership_q8")}</Text>
                <Text className="text-sm text-[#494949] mt-1 mb-6">{i18n.t("faqs.membership_q8_opt1")}</Text>

                <View className="w-full h-[0.5px] bg-brandgold mt-3"></View>

                <Text className="text-black text-base mt-6 mb-1 uppercase font-Crimson underline">{i18n.t("faqs.earning")}</Text>
                <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">{i18n.t("faqs.earning_q1")}</Text>
                <Text className="text-sm text-[#494949] mt-1 mb-6 ">{i18n.t("faqs.earning_q1_opt1")}</Text>

                <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">{i18n.t("faqs.earning_q2")}</Text>
                <Text className="text-sm text-[#494949] mt-1 mb-6 ">{i18n.t("faqs.earning_q2_opt1")}</Text>

                <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">{i18n.t("faqs.earning_q3")}</Text>
                <Text className="text-sm text-[#494949] mt-1 mb-6 ">{i18n.t("faqs.earning_q3_opt1")}</Text>

                <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">{i18n.t("faqs.earning_q4")}</Text>
                <Text className="text-sm text-[#494949] mt-1 mb-6 ">{i18n.t("faqs.earning_q4_opt1")}</Text>

                <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">{i18n.t("faqs.earning_q5")}</Text>
                <Text className="text-sm text-[#494949] mt-1 mb-6 ">{i18n.t("faqs.earning_q5_opt1")}</Text>

                <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">{i18n.t("faqs.earning_q6")}</Text>
                <Text className="text-sm text-[#494949] mt-1 mb-6 ">{i18n.t("faqs.earning_q6_opt1")}</Text>

                <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">{i18n.t("faqs.earning_q7")}</Text>
                <Text className="text-sm text-[#494949] mt-1 mb-6 ">{i18n.t("faqs.earning_q7_opt1")}</Text>

                <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">{i18n.t("faqs.earning_q8")}</Text>
                <Text className="text-sm text-[#494949] mt-1 mb-6 ">{i18n.t("faqs.earning_q8_opt1")}</Text>

                <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">{i18n.t("faqs.earning_q9")}</Text>
                <Text className="text-sm text-[#494949] mt-1 mb-6 ">{i18n.t("faqs.earning_q9_opt1")}</Text>
              </View>
            </View>

            <Footer />
            <CookieConsent />
          </ScrollView>
        </SafeAreaView>
      </ImageBackground>
    </View>
  );
};

export default FAQ;
